import React from 'react'
import './Home.css'
import { Link } from 'react-router-dom'
import { Button } from 'react-scroll'
import { Routes } from 'react-router-dom'
import './About.js'

const Home = () => {
    return (
        <div className='home' id='home'>
            <div className='content'>
                <p>Florida Fire Cider</p>
                <p>Organic. Local. Homemade.</p>
            </div>
        </div>
    )
}

export default Home
