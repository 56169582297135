import React from 'react'
import './About.css'

const About = () => {
    return (
        <div className='about' id='about'>
            <header>What is Fire Cider?</header>
            <span className='line'></span>
            <div class="table-container">
                <div class="table-item first">
                    <p>A healthy immune system is the body’s greatest defense against illness. 
                        Full of anti-bacterial, anti-fungal and anti-viral properties, fire cider 
                        is a wonderful way to treat our bodies kindly during any period of stress.
                        Believed to ward off colds and flus, fire cider has been made in many forms 
                        but was made increasingly popular by herbalist Rosemary Gladstar in the 1970s.</p>
                </div>
                <div class="table-item">
                    <p>Saltwater and Sage’s Fire Cider was born out of a love of plant medicine and 
                        a desire to keep our family healthy and thriving. Our fire cider is made with 
                        all organic ingredients, sourced locally or homegrown when available, and lovingly 
                        steeped for 6 weeks. We sincerely hope that love, kindness and health meet you 
                        at the bottom of this bottle. </p>
                </div>
                </div>
                <div>
                    <p>Best way to use: 1oz daily.
                    In times of illness or stress, increase to 1oz twice daily. It can also be used as a 
                    gargle to help a sore throat.</p>
                    <br></br>

                    <p>Vinegar and honey are great natural preservatives. Fire cider can be refrigerated, 
                        but it is not necessary. Keep in a dark place and use by date on bottle for freshness.</p>
                    <br></br>

                    <p>**The content is for informational purposes only and not intended to be a substitute for 
                        professional medical advice, diagnosis, or treatment, and does not constitute medical 
                        or other professional advice.**</p>
                    <br></br>
                </div>

        </div>
    )
}

export default About
