import React from 'react'
import './Products.css'

const Products = () => {
    return (
        <div className='products' id='products'>
            <header>Products</header>
            <span className='line'></span>
                <p>We offer our original Fire Cider in 8oz bottles and 64oz growlers. </p>
                <br></br>

                <p>Current recipies include:</p>
                <p>Original</p>
                <p>Elderberry</p>
                <p>Spicy!</p>
                <br></br>

                <p>Check back for seasonal batches and new additions</p>
                <br></br>

                <p>For sales or inqueries contact us at saltwaterandsagellc@gmail.com</p>
        </div>
    )
      
}

export default Products
