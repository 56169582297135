import React, { useState } from 'react'
import {Link} from 'react-scroll'
import { FaBars, FaTimes } from 'react-icons/fa'
//import logo from './images/logo.jpg'

import './Navbar.css'

const Navbar = () => {

    const [click, setClick] = useState(false)
    const handleClick = () => setClick(!click)

    const closeMenu = () => setClick(false)

    return (
        <div className='header'>
            <nav className='navbar'>
                <Link to='home' className='brand' smooth={true}>
                    <h1>Saltwater&Sage</h1>
                    {/*<img src={logo} alt='logo' /> */}
                </Link>
                <div className='hamburger' onClick={handleClick}>
                    {click ? (<FaTimes size={30} style={{ color: '#ffffff' }} />)
                        : (<FaBars size={30} style={{ color: '#ffffff' }} />)}

                </div>
                <ul className={click ? "nav-menu active" : "nav-menu"}>
                    <li className='nav-item'>
                        <Link to='home' spy={true} smooth={true} offset={-100} duration={400} onClick={closeMenu}>Home</Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='about' spy={true} smooth={true} offset={-90} duration={500} onClick={closeMenu}>About</Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='ingredients' spy={true} smooth={true} offset={-90} duration={500} onClick={closeMenu}>Ingredients</Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='products' spy={true} smooth={true} offset={-90} duration={500} onClick={closeMenu}>Products</Link>
                    </li>
                </ul>
            </nav>
        </div>
    )
}

export default Navbar
