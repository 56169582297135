import React from 'react'
import Navbar from './components/Navbar'
import Home from './components/Home'
import About from './components/About'
import Products from './components/Products'
import Ingredients from './components/Ingredients'
import Footer from './components/Footer'

function App() {
  return (
    <div>
      <Navbar />
      <Home />
      <About />
      <Ingredients />
      <Products />
      <Footer />
    </div>
  );
}

export default App;
