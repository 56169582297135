import React from 'react'
//import ginger from './images/ginger.jpg'
//import garlic from './images/garlic.jpg'
//import onion from './images/onion.jpg'
//import jalapeno from './images/jalapeno.jpg'
//import carrot from './images/carrot.jpg'
//import honey from './images/honey.jpg'
import './Ingredients.css'

const Ingredients = () => {
    return (
        //Awaiting info on health benefits of each ingredient. Links to or add description.
        <div className='ingredients' id='ingredients'>
            <header>Ingredients</header>
                <p>Ginger</p>
                <p>Garlic</p>
                <p>Onion</p>
                <p>Jalapeno</p>
                <p>Carrot</p>
                <p>Honey</p>
                <p>Apple Cider Vinegar</p>
                <p>Tumeric</p>
                <p>Rosemary</p>
            </div>
        /* Awaiting updated photos. Place into new carousel component.
        <div className='ingredients' id='ingredients'>
            <header>Ingredients</header>
            <container>
                <img src={ginger} alt='ginger' />
                <img src={garlic} alt='garlic' /><br></br>
                <img src={onion} alt='onion' />
                <img src={jalapeno} alt='jalapeno' /><br></br>
                <img src={honey} alt='honey' />
                <img src={carrot} alt='carrot' />
            </container>
        </div>
        */
    )
}

export default Ingredients